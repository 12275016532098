import React, { Fragment, useEffect, useState } from 'react';
import { withFormik, Field as Fileds } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { MdCheck } from 'react-icons/md';
import Form from '../../Components/Form/Form';
import Field from '../../Components/Form/Field/Field';
import { restRequest } from '../../Helpers/RequestHelper';
import FormAction from '../../Components/Form/FormAction';
import { getOrganizationDate, confirmationAlert } from '../../Helpers';
import { validateCreateAccountForm } from '../../Helpers/FormValidator';
import { Checked } from '../../Assets/List/ListIcons';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().required('Account name is required'),
    account_category_id: Yup.string().required('Account Category is required'),
  }),
  mapPropsToValues: ({
    title,
    tax_id,
    balance,
    currency,
    account_no,
    description,
    account_types,
    account_taxes,
    bank_account_no,
    account_subtypes,
    parent_account_id,
    account_category_id,
    selected_category_id,
    account,
    is_sub_account,
    current_balance,
  }) => ({
    title: title || '',
    account: account || null,
    balance: balance || 0,
    current_balance: current_balance || 0,
    currency: currency || null,
    account_no: account_no || '',
    description: description || '',
    tax_id: account_taxes ? tax_id : null,
    bank_account_no: bank_account_no || '',
    parent_account_id: parent_account_id || null,
    account_type_id: account_types[0]?.id || '',
    account_category_id: selected_category_id || account_category_id || '',
    account_subtype_id: account_subtypes[0]?.id || '',
    balance_date: moment(getOrganizationDate()) || null,
    is_sub_account: is_sub_account || false,
  }),

  mapPropsToStatus: (props) => {
    if (props.edit) {
      return {
        currency: props.currency,
        current_balance: props.current_balance,
        is_sub_account: props.is_sub_account,
        // tax_id: props.account_taxes,
        loadingAccountGroups: false,
        account_category_id: props.account_category_id,
        accountType: props.accountType,
        description: props.description,
        showAccountGroupCreateModal: false,
        accountGroups: props.accountGroups,
        account_type_id: props.account_type_id,
        account_subtype_id: props.account_subtype_id,
        title: props.title,
        tax_id: props.tax_id,
        account_no: props.account_no,
        description: props.description,
        balance: props.balance,
        parent_account_id: props.parent_account_id,
        bank_account_no: props.bank_account_no,
        account: props.account,
      };
    } else {
      return {
        account: props.account,
        currency: props.currency,
        tax_id: props.account_taxes,
        loadingAccountGroups: false,
        accountType: props.accountType,
        showAccountGroupCreateModal: false,
        accountGroups: props.accountGroups,
        account_type_id: props.account_type_id,
        account_subtype_id: props.account_subtype_id,
      };
    }
  },
  onSubmit: (values, { setSubmitting, props }) => {
    props.handleSubmit(values, { setSubmitting, props });
  },
});

const AccountFormEnhancer = ({
  title,
  props,
  edit,
  values,
  status,
  errors,
  inModal,
  onClose,
  history,
  onSubmit,
  touched,
  currency,
  handleBlur,
  handlePrompt,
  handleChange,
  isSubmitting,
  account_taxes,
  setFieldValue,
  account_types,
  accountTypeslug,
  parent_accounts,
  setFieldTouched,
  account_subtypes,
  account_categories,
  accountSubtypeSlug,
  markedBalancesheet,
  is_sub_account,
  selected_category_id,
  itemModal = false,
}) => {
  console.log({ err: Object.values(errors).length });
  const [showAlert, setShowAlert] = useState(false);
  const [accountTypes, setAccountTypes] = useState(account_types || []);
  const [isMarkedbalanceSheet, setIsMarkedBalanceSheet] = useState(
    markedBalancesheet || false
  );
  const [accountSubTypes, setSubAccountTypes] = useState(
    account_subtypes || []
  );
  const [accountTaxes, _setaccounttaxes] = useState(account_taxes || []);
  const [parentAccounts, setParentAccounts] = useState(parent_accounts || []);
  const [typeSlug, setTypeSlug] = useState(accountTypeslug);
  const [subTypeSlug, setSubTypeSlug] = useState(accountSubtypeSlug);
  useEffect(() => {
    handleAlert();
  }, [values]);
  const handleAlert = () => {
    let { checkout } = validateCreateAccountForm(values);
    handlePrompt(!checkout);
    return checkout;
  };
  useEffect(() => {
    if (edit) setFieldValue('is_sub_account', Boolean(values.is_sub_account));
  }, [edit, values.is_sub_account]);
  const handleCancel = () => {
    let status = handleAlert();
    let response = true;
    if (!status) {
      response = confirmationAlert();
    }
    if (status || response) {
      if (history?.location?.pathname.includes('/accounting/account')) {
        history.push('/accounting/account');
      }
      inModal ? onClose() : history.goBack();
    }
  };
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const handleCategories = (value) => {
    // account_categories;
    const current = account_categories.find((cate) => cate.id === value.id);

    if (value.id) {
      setIsMarkedBalanceSheet(!!current.balance_sheet);

      restRequest('get', `accounts/types/${value.id}`).then((res) => {
        setAccountTypes(res?.account_types);
        setSubAccountTypes(res?.account_subtypes);
        setFieldValue('account_category_id', value.id);
        setTypeSlug(res.account_types[0].basic_type?.slug);
        setFieldValue('account_type_id', res?.account_types[0]?.id);
        setSubTypeSlug(res.account_subtypes[0].basic_sub_type?.slug);
        setFieldValue('account_subtype_id', res?.account_subtypes[0]?.id);
      });
    }
  };
  const handleAccountTypes = (option) => {
    setFieldValue('account_type_id', option.id);
    restRequest('get', `accounts/subtypes/${option.id}`, {}).then((data) => {
      setFieldValue('account_subtype_id', data?.account_subtypes[0]?.id);
      setSubAccountTypes(data.account_subtypes);
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let updatedValues = {
      ...values,
      balance:
        isMarkedbalanceSheet &&
        !restrictedArray.includes(typeSlug) &&
        subTypeSlug !== 'depreciable_assets'
          ? values.balance !== undefined
            ? values.balance
            : null
          : null,
      balance_date:
        isMarkedbalanceSheet &&
        !restrictedArray.includes(typeSlug) &&
        subTypeSlug !== 'depreciable_assets'
          ? values.balance_date !== undefined
            ? values.balance_date
            : null
          : null,
      bank_account_no:
        typeSlug === 'bank_accounts' ? values.bank_account_no : null,
      is_sub_account: values.is_sub_account,
    };
    onSubmit(updatedValues);
  };
  const handleParentccount = (e) => {
    const isChecked = e.target.checked;
    setFieldValue('is_sub_account', isChecked);

    if (isChecked) {
      const url = edit
        ? `accounts/parent/${values?.account_category_id}/${
            values?.account_type_id
          }/${values?.account_subtype_id}${
            edit ? '?account_id=' + values?.account.id : ''
          }`
        : `accounts/parent/${values?.account_category_id}/${values?.account_type_id}/${values?.account_subtype_id}`;

      restRequest('get', url).then((data) => {
        setParentAccounts(data.parent_accounts);
      });
    } else {
      setFieldValue('parent_account_id', null);
    }
  };
  const restrictedArray = ['accounts_payable', 'accounts_receivable'];
  console.log({isMarkedbalanceSheet})
  return (
    <Fragment>
      <Form onSubmit={handleSubmit} className="account-form float-left w-100">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
          }}
        >
          <h4>Account Category</h4>
          {account_categories?.map((data) => (
            <label key={data.id}>
              <Fileds
                type="radio"
                value={data.id}
                id="account_category_id"
                name="account_category_id"
                disabled={
                  edit
                    ? (edit && values?.account.is_default === 1) ||
                      values?.account.has_child_account
                    : false || itemModal
                }
                checked={values.account_category_id === data.id}
                onChange={() => handleCategories(data)}
              />
              {data.name}
            </label>
          ))}
        </div>
        <div className="form-fields-container d-flex flex-wrap  position-relative pb-20">
          <Field
            required
            type="dropdown"
            dropdownValue="id"
            name="Account type"
            clearable={false}
            className="flex-33"
            id="account_type_id"
            dropdownLabel="name"
            disabled={
              edit
                ? !Boolean(values.account_category_id) ||
                  (edit &&
                    (values.account.is_default === 1 ||
                      values.account.has_child_account))
                : false || itemModal
            }
            options={accountTypes}
            value={values.account_type_id}
            handleChange={(option) => handleAccountTypes(option)}
          />

          <Field
            required
            name="Sub-type"
            type="dropdown"
            dropdownValue="id"
            className="flex-33"
            clearable={false}
            dropdownLabel="name"
            id="account_subtype_id"
            disabled={
              edit
                ? !Boolean(values.account_type_id) ||
                  (edit && values.account.is_default === 1) ||
                  values.account.has_child_account
                : false || itemModal
            }
            options={accountSubTypes}
            value={values.account_subtype_id}
            handleChange={(option) =>
              setFieldValue('account_subtype_id', option.id)
            }
          />
          <Field
            required
            id="title"
            autoFocus={true}
            className="flex-33"
            name="Account name"
            value={values.title}
            placeholder="Enter account title"
            disabled={edit ? edit && !values.account?.can_rename : false}
            onBlur={handleBlur}
            handleChange={handleChange}
            error={touched.title && errors.title}
          />
          <Field
            // type="number"
            id="account_no"
            placeholder="Enter account number"
            // decimalScale={6}
            name="Account No"
            onBlur={handleBlur}
            className="flex-33"
            thousandSeparator=","
            value={values.account_no}
            handleChange={handleChange}
          />
          <Field
            type="textarea"
            id="description"
            name="Description"
            onBlur={handleBlur}
            placeholder="Description"
            value={values.description}
            handleChange={handleChange}
            className="flex-50 textarea-height"
          />
          {typeSlug === 'bank_accounts' && (
            <Field
              className="flex-33"
              name="Bank Account Number"
              placeholder="Enter bank account number"
              // value={values.openingBalance}
              id="bank_account_no"
              handleChange={handleChange}
              onBlur={handleBlur}
              value={values.bank_account_no}
              // decimalScale={6}
              // thousandSeparator=","
              // error={touched.openingBalance && errors.openingBalance}
            />
          )}
          <label className="checkbox--option-label margin-vertical-sm">
            <input
              type="checkbox"
              name="is_sub_account"
              id="is_sub_account"
              checked={values.is_sub_account}
              onChange={handleParentccount}
            />
            <span>
              <Checked />
            </span>
            Is sub account
          </label>
          <Field
            type="dropdown"
            dropdownValue="id"
            className="flex-33"
            clearable={false}
            name="Sub Account"
            dropdownLabel="title"
            id="parent_account_id"
            options={parentAccounts}
            placeholder="Select Sub Account"
            disabled={!values.is_sub_account}
            value={values.parent_account_id || null}
            handleChange={(option) =>
              setFieldValue('parent_account_id', option.id ? option.id : null)
            }
          />
          <Field
            required
            id="currency"
            name="currency"
            type="dropdown"
            disabled={true}
            dropdownValue="id"
            className="flex-33"
            value={currency.id}
            options={[currency]}
            dropdownLabel="name"
            error={touched.openingBalance && errors.openingBalance}
          />
          <Field
            id="tax_id"
            type="dropdown"
            clearable={false}
            name="Default Tax"
            dropdownValue="id"
            className="flex-33"
            dropdownLabel="name"
            value={values.tax_id}
            options={accountTaxes}
            placeholder="Select default tax"
            handleChange={(option) =>
              setFieldValue('tax_id', option.id ? option.id : null)
            }
          />
          {!edit ? (
            <>
              {isMarkedbalanceSheet &&
                !restrictedArray.includes(typeSlug) &&
                subTypeSlug !== 'depreciable_assets' && (
                  <>
                    <Field
                      required
                      id="balance"
                      type="number"
                      name="Balance"
                      placeholder="$0"
                      decimalScale={6}
                      className="flex-33"
                      thousandSeparator=","
                      onBlur={handleBlur}
                      value={values.balance}
                      error={touched.balance && errors.balance}
                      onValueChange={(value) =>
                        setFieldValue('balance', value.floatValue)
                      }
                    />
                    <Field
                      disabled
                      type="date"
                      id="balance_date"
                      className="flex-25"
                      name="Balance As date"
                      minDate={values.balance_date}
                      onBlur={() => setFieldTouched('balance_date', true)}
                      value={
                        typeof values.balance_date == 'string'
                          ? moment(values.balance_date)
                          : values.balance_date
                      }
                      handleChange={(date) => {
                        setFieldValue('balance_date', date);
                      }}
                    />
                  </>
                )}
            </>
          ) : (
            <>
              <div>
                <span>Current Balance </span>
                <span>{`${parseFloat(values.current_balance).toFixed(
                  2
                )}`}</span>
              </div>
            </>
          )}
        </div>
        <div
          className="float-left w-100 action-buttons"
          style={{
            borderTop: '1px solid var(--border-light)',
            marginRight: '0rem',
          }}
        >
          <FormAction
            disabled={Object.values(errors).length}
            onCancel={handleCancel}
          />
        </div>
      </Form>
    </Fragment>
  );
};

export default formEnhancer(AccountFormEnhancer);
