import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MdAdd, IoMdCheckmarkCircle } from '../../Common/Icons';
import orgImage from '../../Assets/Img/org-image.jpg';
import {
  getCurrentOrganization,
  setCurrentOrganization,
} from '../../Helpers/CurrentSession';
import { restRequest } from '../../Helpers/RequestHelper';
import { imagePathS3 } from '../../Helpers/RequestHelper';
import './SidePanel.css';
import {
  checkError,
  getOrganization,
  logout,
  setOrganization,
} from '../../Helpers/AuthHelper';
import Loader from '../Loader/Loader';
import WithContext from '../../Context/UserContextHOC';
import Toaster from '../../Helpers/Toaster/Toaster';
import { CustomModal } from '../../Components';
import GeneralModule from './GeneralModule';


class SidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      loading: false,
      currentOrg: {},
      toastMessage: '',
      toastType: null,
      moduleSharingModal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    // document.title = 'SidePanel'
    const sidebar = localStorage.getItem("organizationSideBar")
    if (sidebar) {
      this.fetchOrgData()
    } else this.fetchData();

  }


  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', `organizations`)
      .then((res) => {
        let currentOrg = res.organizations.filter(
          (org) => org.organizations.id === getCurrentOrganization().id
        );
        this.setState({
          organizations: res.organizations,
          loading: false,
          currentOrg: currentOrg[0],
          orgLogo:
            currentOrg[0] && currentOrg[0].organizations.logo
              ? imagePathS3(currentOrg[0].organizations.logo)
              : orgImage,
          orgName: currentOrg[0] && currentOrg[0].organizations.name,
          orgEmail:
            currentOrg[0] && currentOrg[0].organizations.primary_contact_email,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }
  async fetchOrgData() {
    this.setState({ loading: true });
    await restRequest('get', `books_organizations`)
      .then((res) => {

        this.setState({
          organizations: res.organizations,
          loading: false,

        });
      })
      .catch((error) => {
        checkError(error);
      });
  }

  handleSwitchOrganization(organizationId, oranizationName, platform_type) {
    // let string_to_split = platform_type
    let result = platform_type.split(',')

    if (result.includes("ims")) {
      this.setState({
        loading: true,
      });
      const currentOrganizationId = parseInt(getOrganization());
      const {
        context: { changeRole },
        // history,
      } = this.props;

      if (currentOrganizationId === organizationId) {
        this.setState({
          loading: false,
        });
        return;
      }

      restRequest('get', `organizations/${organizationId}`)
        .then((res) => {
          localStorage.setItem('switch_org', JSON.stringify(true));
          localStorage.setItem('org_switch', true);
          restRequest('get', 'currentuser/role').then((role) => {
            changeRole(role);
            setOrganization(organizationId);
            setCurrentOrganization(res);
            window.location.href = '/dashboard';
            this.setState({
              loading: false,
            });
          });
        })
        .catch((error) => {
          checkError(error);
          this.setState({
            loading: false,
            toastMessage: error.response && error.response.data.message,
            toastType: 'error',
          });
          setTimeout(() => {
            this.setState({
              toastMessage: '',
              toastType: null,
            });
          }, 6000);
        });
    } else {


      this.setState({
        moduleSharingModal: true,
        organization_Id: organizationId,
      })
    }
  }

  handleSubmit(payload = "") {
    restRequest("post", "modules_permissions",
      {
        organization_id: this.state.organization_Id,
        modules: [...payload]
      }
    ).then((res) => {
      if (res) {
        let org_id = this.state.organization_Id
        this.setState({
          organization_Id: null,
          moduleSharingModal: false,
        })
        window.location.reload()
      }

      localStorage.removeItem("organizationSideBar")

    })
  }

  handleClose() {

    this.setState({ moduleSharingModal: false })
  }

  renderCreateModal = () => {
    return (
      <CustomModal
        showModal={this.state.moduleSharingModal}
        title="Organization Sharing"
        renderActions={false}
        size="small"
        onClose={this.handleClose}
      >
        <GeneralModule handleSubmit={this.handleSubmit} handleClose={this.handleClose} />

      </CustomModal>
    );
  }

  render() {
    const { ssoConnected } = this.props;
    return (
      <>
        {this.state.moduleSharingModal && this.renderCreateModal()}
        {this.state.toastMessage !== '' && (
          <Toaster
            message={this.state.toastMessage}
            type={this.state.toastType}
          />
        )}
        {/* <div className={`${this.props.showSidePanel ? "sidenav-overlay sidenav-overlayshow" : "sidenav-overlay"}`}></div> */}
        {/* <div class="sidenav-overlay"></div> */}
        <div
          id="sidep"
          className={`sidepanel sidebar-mob ${this.props.showSidePanel ? 'panelshow' : 'panelhide'
            }`}
        >
          <div className="intro-orginization">
            <div className="image">
              {this.state.loading ? (
                <Loader />
              ) : (
                <div className="image_circle">
                  <img src={this.state.orgLogo} alt="Organization pic"></img>
                </div>
              )}
            </div>
            <div className="name">{this.state.orgName}</div>
            <div className="user-email">{this.state.orgEmail}</div>
            <ul>
              {ssoConnected ? (
                <li>
                  <button
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_SSO_REDIRECT_DEV}/myProfile`
                      );
                      this.props.closeMe();
                    }}
                  >
                    My Account
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    onClick={() => {
                      this.props.history.push('/myaccount');
                      this.props.closeMe();
                    }}
                  >
                    My Account
                  </button>
                </li>
              )}

              <li>
                <button
                  id="click_signout_button"
                  onClick={() => logout()}
                >
                  Sign out
                </button>
              </li>
            </ul>
          </div>
          {this.state.loading ? (
            <Loader />
          ) : (
            <ul className="oganization-list">
              <li
                onClick={() => this.props.closeMe()}
                style={{ cursor: 'text' }}
              >
                My Business{' '}
                <Link to="/organization/add">
                  <span title="Add Business">
                    <MdAdd className="add-organziation" />{' '}
                  </span>
                </Link>
              </li>
              {this.state.organizations?.map((org, index) => {
                return (
                  <li
                    className={` display-flex ${getCurrentOrganization()?.id === org.organizations?.id // && !Object.keys(this.state.currentOrg)
                      ? 'active'
                      : ''
                      }`}
                    onClick={() =>
                      this.handleSwitchOrganization(
                        org.organization_id,
                        org.name,
                        org?.platform_type
                      )
                    }
                    key={index}
                  >
                    <div className="org-name">
                      {org.organizations ? org.organizations.name : org.name}
                      <div className="small-sidepanel">{`Package : ${org?.organizations?.organization_plan ?
                        org?.organizations?.organization_plan.name?.split(
                        ' '
                        )[0]
                        : "Free"
                        } `}</div>
                    </div>
                    <div className="circle_align">
                      <span
                        className={
                          org.role_id === 1 ? 'owner-label' : 'invited-label'
                        }
                      >
                        {org.role_id === 1 ? 'Owner' : 'Invited'}
                      </span>{' '}
                      <IoMdCheckmarkCircle className="checkcircle" />
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </>
    );
  }
}
export default WithContext(SidePanel);
