import React, { Component } from 'react';
import classNames from 'classnames';
import AccountForm from './AccountForm';
import queryString from 'query-string';
import Loader from '../../Components/Loader/Loader';
import EditIcon from '../../Assets/General/EditIcon';
import { checkError } from '../../Helpers/AuthHelper';
import { Button, CheckedMenu } from '../../Components';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import { prepareDataForTable } from './AccountTableData';
import { restRequest } from '../../Helpers/RequestHelper';
import ListTable from '../../Components/ListTable/ListTable';
import CustomModal from '../../Components/CustomModal/CustomModal';
import { NewAccountIcon } from '../../Assets/Settings/SettingsIcon';
import { setObjInLS, setValueInLS } from '../../Helpers/LocalStorage';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import InventoryAccountIcon from '../../Assets/General/InventoryAccountIcon';
import ActiveIcon from '../../Assets/General/ActiveIcon';
import InactiveIcon from '../../Assets/General/InactiveIcon';
class AccountList extends Component {
  state = {
    data: [],
    allData: [],
    loading: true,
    activePage: 1,
    totalPage: 1,
    currentPage: 1,
    viewPerPage: 20,
    totalRecords: 0,
    orderBy: 'desc',
    accountId: null,
    totalRecords: 0,
    showPrompt: false,
    sortFilter: 'created_at',
    showEditAccountModal: false,
    showAccountNameCreateModal:
      window.location.search.slice(1).split('=')[0] === 'new',
  };

  componentDidMount() {
    document.title = 'Chart of Accounts';
    setValueInLS('fullscreen', true);
    this.fetchDataWithQueryString();
  }
  componentDidUpdate() {
    return this.handleOnUpdateQueryString();
  }
  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view } = this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
      },
      () => this.fetchData()
    );
  }

  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view } = this.parseQueryString();
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
  }
  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    let sort = '';
    switch (query.sort) {
      case 'title':
      case 'currency':
      case 'tax_name':
      case 'account_no':
      case 'bank_account_no':
      case 'account_type_name':
      case 'parent_account_name':
      case 'account_subtype_name':
      case 'account_category_name':
      case 'balance':
      case 'created_at':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    return {
      page,
      sort,
      view,
      filter,
      orderBy,
    };
  }
  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };
  sortOptions = [
    {
      id: 'title',
      type: 'link',
      label: 'Name',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('title') }),
    },
    {
      id: 'account_category_name',
      type: 'link',
      label: 'Category',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('account_category_name'),
        }),
    },
    {
      id: 'account_type_name',
      type: 'link',
      label: 'Type',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('account_type_name'),
        }),
    },
    {
      id: 'account_subtype_name',
      type: 'link',
      label: 'Sub Type',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('account_subtype_name'),
        }),
    },
    {
      id: 'parent_account_name',
      type: 'link',
      label: 'Parent Account',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('parent_account_name'),
        }),
    },
    {
      id: 'currency',
      type: 'link',
      label: 'Currency',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('currency'),
        }),
    },
    {
      id: 'bank_account_no',
      type: 'link',
      label: 'Bank Account Number',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('bank_account_no'),
        }),
    },
    {
      id: 'account_no',
      type: 'link',
      label: 'Account Number',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('account_no'),
        }),
    },
    {
      id: 'tax_name',
      type: 'link',
      label: 'Default Tax',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('tax_name'),
        }),
    },
    {
      id: 'balance',
      type: 'link',
      label: 'Current Balance',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('balance'),
        }),
    },
    {
      id: 'created_at',
      type: 'link',
      label: 'Created Time',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
  ];
  checkDeletable = (id) =>
    this.state.allData.find((one) => one.id === id).deletable === 0;
  deleteAccountData = (id) => this.openDeleteModal(id);
  hasDeltePermission = () => this.hasPermission('account', 'Delete');
  hasEditPermission = () => this.hasPermission('account', 'Edit');
  hasActivePermission = () => this.hasPermission('account', 'Active');
  hasInActivePermission = () => this.hasPermission('account', 'Inactive');
  showCreateModal = () => this.setState({ showAccountNameCreateModal: true });
  showAccountNameCreateModal = () =>
    this.setState({ showAccountNameCreateModal: true });
  showEditModal = (id) => {
    return this.setState({
      showEditAccountModal: true,
      accountId: id,
    });
  };

  renderMultipleActionMenu = () => (
    <CheckedMenu count={this.state.bulkSelectedData.length}>
      <Button
        type="delete"
        enable={this.hasPermission('Accounts', 'All')?.[2]}
        handleClick={this.openBulkDeleteModal}
      />
    </CheckedMenu>
  );

  fetchData = () => {
    this.setState({ loading: true });
    restRequest(
      'get',
      `accounts/?page=${this.state.currentPage}&sort_column=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}`
    )
      .then((res) => {
        this.setState({
          allData: res?.accounts.data,
          data: prepareDataForTable(res?.accounts.data),
          totalRecords: res?.accounts?.total,
          totalPage: res.accounts.last_page,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  };

  prepareRowOptions = () => [
    {
      label: 'Edit',
      icon: EditIcon,
      onClick: (id) => this.showEditModal(id),
      disabled: () => !this.hasEditPermission(),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.deleteAccountData(id),
      disabled: (id) => !this.hasDeltePermission() || this.checkDeletable(id),
    },
    {
      label: 'Active',
      icon: ActiveIcon,
      onClick: (id) => this.activeAccountData(id),
      disabled: (id) =>
        !this.hasActivePermission() || this.checkActiveStatus(id) !== 'active',
    },
    {
      label: 'InActive',
      icon: InactiveIcon,
      onClick: (id) => this.inActiveAccountData(id),
      disabled: (id) =>
        !this.hasInActivePermission() ||
        this.checkActiveStatus(id) !== 'inactive',
    },
  ];
  checkActiveStatus = (id) => {
    const data = this.state.allData?.find((val) => val.id === id);
    if (!data) return false;
    if (!data.is_default) {
      if (data.is_active) {
        return 'inactive';
      } else {
        return 'active';
      }
    } else {
      return true;
    }
  };

  activeAccountData(id) {
    restRequest('put', id && `accounts/${id}/active`).then((res) => {
      this.fetchData();
      this.props.handleToast(res.message, 'success');
    });
  }
  inActiveAccountData(id) {
    restRequest('put', id && `accounts/${id}/inactive`).then((res) => {
      this.fetchData();
      this.props.handleToast(res.message, 'success');
    });
  }
  render() {
    if (this.state.loading) return <Loader />;
    return this.renderTable();
  }

  renderCreateAccountNameModal = () => (
    <CustomModal
      fil="#000000"
      size="large"
      renderActions={false}
      Icon={InventoryAccountIcon}
      title="Create Inventory Account"
      modaltitle="inventory_modal_title"
      showPrompt={this.state.showPrompt}
      showModal={this.state.showAccountNameCreateModal}
      className={classNames('create_new_account_inventory')}
      onClose={() => this.setState({ showAccountNameCreateModal: false })}
    >
      <AccountForm
        inModal
        history={this.props.history}
        handlePrompt={this.handlePrompt}
        handleToast={this.props.handleToast}
        onClose={() => this.setState({ showAccountNameCreateModal: false })}
        onSubmit={() => {
          this.fetchData();
          this.setState({ showAccountNameCreateModal: false });
        }}
      />
    </CustomModal>
  );
  renderEditAccountNameModal = () => (
    <CustomModal
      size="large"
      fil="#000000"
      renderActions={false}
      Icon={InventoryAccountIcon}
      title="Edit Inventory Account"
      modaltitle="inventory_modal_title"
      showPrompt={this.state.showPrompt}
      showModal={this.state.showEditAccountModal}
      className={classNames('create_new_account_inventory')}
      onClose={() => this.setState({ showEditAccountModal: false })}
    >
      <AccountForm
        inModal
        edit={true}
        type="edit"
        id={this.state.accountId}
        history={this.props.history}
        handlePrompt={this.handlePrompt}
        handleToast={this.props.handleToast}
        onClose={() => this.setState({ showEditAccountModal: false })}
        onSubmit={() => {
          this.fetchData();
          this.setState({ showEditAccountModal: false });
        }}
      />
    </CustomModal>
  );

  renderTable() {
    console.log({ this: this.state });
    // const hasAccountCreatePermission = this.hasPermission('account', 'Create');

    return (
      <>
        {this.renderCreateAccountNameModal()}
        {this.renderEditAccountNameModal()}
        <ListTable
          {...this.state}
          hasCheckbox={true}
          data={this.state.data}
          className="system_settings"
          moduleName="Accounts"
          loading={this.state.loading}
          orderBy={this.state.orderBy}
          generateRoute={this.generateRoute}
          sortFilter={this.state.sortFilter}
          handleCheckAll={this.handleCheckAll}
          rowOptions={this.prepareRowOptions()}
          totalRecords={this.state.totalRecords}
          selectedData={this.state.bulkSelectedData}
          handleCheckSingle={this.handleCheckSingle}
        />
      </>
    );
  }
}

export default HeaderWrapper(AccountList, {
  name: 'Accounts',
  createModal: true,
  baseUrl: 'accounts',
  Icon: NewAccountIcon,
  deleteName: 'Account',
  permissionName: 'account',
  routeName: 'setting/account',
  buttonLabel: 'Add New Account',
});
