import React, { Component, Fragment } from 'react';

import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';
import { checkError } from '../../../Helpers/AuthHelper';
import { formatNumber } from '../../../Helpers/FormatNumber';
import Box from '../../../Components/Layout/Box';
import { ActionMenu } from '../../../Components/CheckedMenu/CheckedMenu';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import ItemDetailsImages from './Info/ItemDetailsImages';
import Loader from '../../../Components/Loader/Loader';
import Table from '../../../Components/Table/Table';
import ItemAccountInformation from './Tabs/MoreDetails/ItemAccountInformation';
import NotFound from '../../../Components/NotFound/NotFound';
import ItemQuantity from './Quantity/ItemQuantityDetails';
import { CustomModal } from '../../../Components';
import ItemInfo from './Info/ItemInfo';
import pageIdentify from '../../../Helpers/PageIdentify';
import {
  getObjFromLS,
  getStringValueFromLS,
  setObjInLS,
  setValueInLS,
} from '../../../Helpers/LocalStorage';
import SubTabs from '../../../Components/Tabs/SubTabs';
import AmountInformation from './Amount/AmountInformation';
import ItemActivity from './Activity/ItemActivity';
import EmptyTable from '../../../Components/ListTable/EmptyTable';
import StockQuantity from './Stock/StockQuantity';
import TabsTable from '../../../Components/TabsTable/TabsTable';
import ItemTransactionTab from './Tabs/Transaction/ItemTransactionTab';
import { MdClose, SiWordpress, SiShopify } from '../../../Common/Icons';
import ImageCropper from '../../../Components/Cropper/ImageCropper';
import { wooComItemConfirmDialogContent } from '../../../static';
import classNames from 'classnames';
import '../View/ItemList.css';
import { Button as CommonButton } from '../../../Common';
import { HeaderMenus } from '../../../Components';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';

class ItemDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props?.match?.params?.id;

  state = {
    item: {},
    data: {},
    stocks: [],
    detailsNo: '',
    currency: {},
    imageUploading: false,
    base64Images: [],
    imageFile: [],
    showImageCropModal: false,
    loading: true,
    notFound: false,
    accessDenied: false,
    // Due to SIQ-5993 currentTab change to sale info
    // currentTab: this.props.inModal ? 'transactions' : 'salesInfo',
    currentTab: 'salesInfo',
    alreadyUploadImage: false,
    openModal: false,
    modelIndex: 0,
    ishideMenu: false,
    warehouseId: null,
  };

  componentDidMount() {
    if (!this.props.inModal) {
      document.title = 'Item Details';
    }
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await restRequest('get', `items/${this.id}`)
      .then((res) => {
        const item = {
          id: res.id,
          name: res.name,
          images: res.images,
          primary_image: res.item_primary_image,
          sku: res.sku,
          oppeningStock: res.oppeningStock,
          OpeningStockValue: res.opening_stock_value * res.oppeningStock,
          unit: res.unit,
          physicalStock: res.physicalStock,
          accountingStock: res.accountingStock,
          varient: res.group_variant,
          upc: res.upc,
          mpn: res.mpn,
          weight: res.weight,
          dimensions: res.dimensions,
          ean: res.ean,
          inventory_type: res.inventory_type,
          isbn: res.isbn,
          taxable: res.taxable,
          type: res.type,
          shiped: res.to_be_shipped,
          received: res.to_be_received,
          reorder: res.reorder_level,
          description: res.description,
          manufacturer: res.manufacturer,
          brand: res.brand,
          tax: res.tax && res.tax.name,
          taxFull: res.tax,
          isActive: res.is_active,
          purchaseAccount: res.purchase_account,
          purchaseUnitPrice: res.purchase_unit_price,
          purchaseDescription: res.purchase_description,
          salesAccount: res.sales_account,
          salesUnitPrice: res.sales_unit_price,
          salesDescription: res.sales_description,
          vendor: res.vendor,
          itemGroup: res.itemGroup,
          association: res.association || [],
          platform_type: res.platform_type
        };
        this.setState({
          item,
          detailsNo: item.name,
          currency: res.base_currency,
          stocks: res.stocks,
          warehouseId: res?.stocks[0]?.warehouse_id,
          loading: false,
        });
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          presistDetailPage: true,
        });
      })
      .catch((error) => {
        //checkError(error,this.props.handleToast)
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.action && dataFromLS.action === 'delete') {
          checkError(error);
        } else {
          checkError(error);
          if (typeof this.props.handleToast !== 'undefined') {
            this.props.handleToast(error, 'error');
          }
        }
        if (error.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ notFound: true, loading: false });
        }
      });

    if (this.state.accessDenied === false && this.state.notFound === false) {
      await this.ItemAdjustmentDetails();
    }
  };

  onDropImages = async (accepted, rejected) => {
    const { handleToast } = this.props;

    if (rejected.length) {
      if (rejected.length === 1) {
        handleToast('1 invalid file/file size.', 'error');
        return;
      }
      handleToast(`${rejected.length} invalid files/file sizes.`, 'error');
      return;
    }

    if (accepted.length) {
      let updatedFiles = accepted.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      await this.setState({ imageFile: updatedFiles });
      this.setState({ showImageCropModal: true });
    }
  };

  renderActionMenu() {
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('item', 'All');
    const canEditGroupItem = this.hasPermission('itemGroups', 'Edit');
    const canCreateGroupItem = this.hasPermission('itemGroups', 'Create');
    const canDeleteGroupItem = this.hasPermission('itemGroups', 'Delete');
    const itemAdjustmentCreate = this.hasPermission('itemadjustment', 'Create');
    const {
      item: { isActive, itemGroup },
    } = this.state;
    const menusList = [
      {
        type: 'link',
        icon: 'edit',
        enable:
          (canEditGroupItem && itemGroup) || (hasEditPermission && !itemGroup),
        to: `/items/edit/${this.id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'link',
        icon: 'clone',
        tooTip: 'Clone',
        mobileLable: 'Clone',
        isMobile: true,
        enable:
          (itemGroup && (canEditGroupItem || canCreateGroupItem)) ||
          (hasCreatePermission && !itemGroup),
        to: `/items/clone/${this.id}`,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            this.id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable:
          (canDeleteGroupItem && itemGroup) ||
          (hasDeletePermission && !itemGroup),
      },
      {
        type: 'button',
        icon: isActive ? 'inactive' : 'active',
        tooTip: isActive ? 'inactive' : 'active',
        isMobile: true,
        mobileLable: isActive ? 'inactive' : 'active',
        enable:
          (canEditGroupItem && itemGroup) || (hasEditPermission && !itemGroup),
        handleClick: () =>
          this.toggleActive(this.id, !isActive ? 'active' : 'inactive'),
      },
      {
        type: 'button',
        icon: 'adjust',
        tooTip: 'adjustment',
        mobileLable: 'adjustment',
        isMobile: true,
        enable: itemAdjustmentCreate && this.checkIfItemAdjustable(),
        handleClick: () => {
          this.props.history.push({
            pathname: `/item-adjustments/add`,
            state: {
              itemId: this.id,
              warehouseId: this.state.warehouseId,
            },
          });
        },
      },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/items/add`),
        label: `New`,
        icon: 'new',
        enable:
          (canCreateGroupItem && itemGroup) ||
          (hasCreatePermission && !itemGroup),
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  checkIfItemAdjustable() {
    return this.state.item.inventory_type === 'inventory' &&
      this.state.item.isActive === 1
      ? true
      : false;
  }

  handleTabChange(tab, val = '') {
    this.setState({ currentTab: tab });
  }

  ItemAdjustmentDetails() {
    restRequest('get', `items/${this.id}/adjustment`)
      .then((res) => {
        this.setState({ data: res });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  }

  tabsForItems = [
    {
      id: 'salesInfo',
      label: 'Sales Information',
      onClick: () => {
        this.handleTabChange('salesInfo', 'sales_info');
      },
      renderContent: () => this.renderItemAmountInfo('sales_info'),
    },

    {
      id: 'purchseInfo',
      label: 'Purchase Information',
      onClick: () => {
        this.handleTabChange('purchseInfo', 'purchase_info');
      },
      renderContent: () => this.renderItemAmountInfo('purchase_info'),
    },

    {
      id: 'location',
      label: 'Stock Location',
      onClick: () => {
        this.handleTabChange('location', 'item_location');
      },
      renderContent: () => this.renderStocksTable(),
    },
    {
      id: 'transactions',
      label: 'Transactions',
      onClick: () => {
        this.handleTabChange('transactions', 'items_transactions');
      },
      renderContent: () => this.renderTableTransaction('items_transactions'),
    },
    {
      id: 'adjustments',
      label: 'Adjustments',
      onClick: () => {
        this.handleTabChange('adjustments', 'item_adjustments');
      },
      renderContent: () => this.renderTableAdjustment('item_adjustments'),
    },
    {
      id: 'activity',
      label: 'Activity',
      onClick: () => {
        this.handleTabChange('activity', 'item_activity');
      },
      renderContent: () => this.renderActivityTab('item_activity'),
    },
  ];

  renderActivityTab = () => {
    return <ItemActivity itemId={this.id} />;
  };

  renderTableTransaction = (value) => {
    return (
      <ItemTransactionTab
        itemId={this.state.item.id}
        item={this.state.item}
        handleToast={this.props.handleToast}
        inModal={this.props.inModal}
      />
    );
  };

  renderTableAdjustment = (value) => {
    return (
      <TabsTable
        value={value}
        data={this.state.data}
        loading={this.state.loading}
        ItemId={this.id}
      />
    );
  };

  handleImageUpload = (updatedFiles, croppedImage) => {
    if (!this.state.alreadyUploadImage) {
      document.body.style.cursor = 'wait';
      updatedFiles[updatedFiles.length - 1].preview = croppedImage;
      updatedFiles.forEach((el) => {
        this.state.base64Images.push(el.preview);
      });
      const data = new FormData();
      for (let [index, imageUrl] of this.state.item.images.entries()) {
        data.append(`images[${index}]`, imageUrl);
      }
      data.append('upload_images', []);
      for (let [index, file] of this.state.base64Images.entries()) {
        data.append(`base84Image[${index}]`, file);
      }
      restRequest('post', `items/${this.id}/images`, data)
        .then((res) => {
          let datafromLS = getObjFromLS('module');
          setObjInLS('module', {
            ...datafromLS,
            action: 'justedit',
            id: this.id,
            urlPath: pageIdentify(window.location.pathname)
              ? window.location.pathname
              : '/items',
            moduleName: 'items',
            queryParam: datafromLS.queryParam ? datafromLS.queryParam : '',
          });
          this.props.history.push('/r');
          this.setState({
            item: {
              ...this.state.item,
              images: res.data.images,
              showImageCropModal: false,
              alreadyUploadImage: false,
              imageFile: [],
              base64Images: [],
              imageUploading: false,
            },
          });
          document.body.style.cursor = 'default';
          this.props.handleToast('Images uploaded successfully.', 'success');
        })
        .catch((error) => {
          checkError(error, this.props.handleToast);
          this.setState({ alreadyUploadImage: false });
        });
    }
  };

  ImageCropModal = () => {
    return (
      <CustomModal
        className="smallWidthClass upload_img_popup"
        showModal={this.state.showImageCropModal}
        title="Adjust Image"
        renderActions={false}
        onClose={() =>
          this.setState({
            showImageCropModal: false,
            imageFile: [],
            base64Images: [],
            imageUploading: false,
          })
        }
      >
        <ImageCropper
          inModal
          updatedFiles={this.state.imageFile}
          type="add"
          onSubmitModal={(updatedFiles, croppedImage) => {
            this.handleImageUpload(updatedFiles, croppedImage);
            this.setState({ imageUploading: true, alreadyUploadImage: true });
          }}
          closeModal={() =>
            this.setState({
              showImageCropModal: false,
              imageFile: [],
              base64Images: [],
              imageUploading: false,
              alreadyUploadImage: false,
            })
          }
          handleToast={this.props.handleToast}
          alreadyUploadInprogress={this.state.alreadyUploadImage}
        />
      </CustomModal>
    );
  };

  renderItemAmountInfo = (value) => {
    const {
      purchaseUnitPrice,
      purchaseAccount,
      purchaseDescription,
      taxFull,
      vendor,
      salesUnitPrice,
      salesAccount,
      salesDescription,
    } = this.state.item;
    if (value === 'purchase_info') {
      return (
        <Fragment>
          {purchaseAccount && (
            <AmountInformation
              price={`${this.state.currency.symbol}${formatNumber(
                purchaseUnitPrice
              )}`}
              account={purchaseAccount.title}
              description={purchaseDescription}
              preferredVendor={vendor}
              inModal={this.props.inModal || false}
            />
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {salesAccount && (
            <AmountInformation
              price={`${this.state.currency.symbol}${formatNumber(
                salesUnitPrice
              )}`}
              account={salesAccount.title}
              description={salesDescription}
              Tax={taxFull}
              inModal={this.props.inModal || false}
            />
          )}
        </Fragment>
      );
    }
  };

  renderStocksTable() {
    const { stocks, item } = this.state;
    if (!stocks.length || item.inventory_type === 'noninventory')
      return <EmptyTable inModal />;
    const tableHeaders = [
      'Warehouse',
      'Opening Stock',
      'Physical Stock',
      'Accounting Stock',
    ];
    const tableData = [
      'warehouseName',
      'openingStock',
      'physicalStock',
      'accountStock',
    ];
    const preparedStocks = stocks.map((stock) => ({
      warehouseName: stock.warehouse.name,
      openingStock: (
        <ul>
          <li>{formatNumber(stock.opening_quantity)}</li>
          <li>
            <small>{`${item.unit ? item.unit + '(s)' : ''}`}</small>
          </li>
        </ul>
      ),
      physicalStock: (
        <ul>
          <li>{formatNumber(stock.physical_quantity)}</li>
          <li>
            <small>{`${item.unit ? item.unit + '(s)' : ''}`}</small>
          </li>
        </ul>
      ),
      accountStock: (
        <ul>
          <li>{formatNumber(stock.accounting_quantity)}</li>
          <li>
            <small>{`${item.unit ? item.unit + '(s)' : ''}`}</small>
          </li>
        </ul>
      ),
    }));
    const rightAlignColumns = [2, 3, 4];
    return (
      <Table
        className="details-table sub_tabs_table"
        list={preparedStocks}
        tableHeaders={tableHeaders}
        tableData={tableData}
        rightAlignColumns={rightAlignColumns}
      />
    );
  }

  async setasPrimary(imagePath) {
    if (typeof imagePath === 'undefined') {
      this.props.handleToast('Please select valid image', 'error');
      return;
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    this.setState({ imageUploading: true });
    const data = new FormData();
    data.append(`image_path`, imagePath);
    data.append(`item_id`, this.id);
    await restRequest('post', `items/primary_image`, data)
      .then((res) => {
        fullscreen && fullscreen === 'false'
          ? this.props.history.push('/r')
          : this.props.history.push(window.location.pathname);
        this.setState({
          item: { ...this.state.item, primary_image: res.data },
        });
        this.props.handleToast(
          'Primary Image selected successfully.',
          'success'
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ imageUploading: false });
      });
    this.setState({ imageUploading: false });
  }

  handleUnlink = (store = {}) => {
    const {
      item: { id },
    } = this.state;
    const { market_place = '', sync_item_id = null, store_id = null } = store;

    const data = new FormData();
    data.append('store_id', store_id);
    data.append('market_place', market_place);
    data.append('sync_item_id', sync_item_id);
    const url =
      market_place === 'woo-commerce'
        ? `woocom/items/unlink/${id}`
        : `shopify/items/unlink/${id}`;
    restRequest('post', url, data)
      .then((_) => {
        this.props.handleToast('Item unlink successfully', 'success');
        this.fetchData();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  LinkToExistingItem = (data) => (
    <div className={classNames('sync-settings-popup')}>
      {data.content[0]}
      <div style={{ paddingTop: '2rem' }}>
        <p>
          Unmapped Item:{' '}
          <b>{`[${this.state.item.sku}] - ${this.state.item.name}`}</b>
        </p>
        <p>Select Master Item:</p>
        <select
          value="API"
          // onChange={(e) => {
          //   SetSyncTime(e.target.value);
          // }}
          style={{ width: '100%', height: '4rem' }}
        >
          {/* {woocommerceSyncSettingTime.map((item, i) => ( */}
          <option value="API integration...">
            'API integration remaining'
          </option>
          {/* ))} */}
        </select>
        <div className={classNames('ecom-action-btns', 'action-btns')}>
          <button
            className={classNames('btn-cancel', 'mr-3')}
            onClick={() => {
              this.setState({ openModal: false });
            }}
          >
            cancel
          </button>
          <CommonButton
            btnText={data.btnText}
            className={classNames('btn-connect')}
            clickHandler={() => {}}
            disabled={false}
            btnLoading={false}
          />
        </div>
      </div>
    </div>
  );

  modalContentHandler = (modelIndex) => {
    const data = wooComItemConfirmDialogContent[modelIndex];
    return (
      <>
        <div className={classNames('disconnect-popup-main')}>
          {modelIndex === 0 ? (
            <>
              <div>
                <h3>{data.subHeading}</h3>
                <ul>
                  {data.content.map((val) => (
                    <li key={val}>{val}</li>
                  ))}
                </ul>
              </div>
              <div className={classNames('ecom-action-btns', 'action-btns')}>
                <button
                  className={classNames('btn-cancel', 'mr-3')}
                  onClick={() => {
                    this.setState({ openModal: false });
                  }}
                >
                  cancel
                </button>
                <CommonButton
                  btnText={data.btnText}
                  className={classNames('btn-connect')}
                  clickHandler={() => {}}
                  disabled={false}
                  btnLoading={false}
                />
              </div>
            </>
          ) : (
            this.LinkToExistingItem(data)
          )}
        </div>
      </>
    );
  };

  renderLayoutView() {
    const { item, imageUploading, currentTab, openModal, modelIndex } =
      this.state;
    const {
      // name,
      sku,
      images,
      primary_image,
      unit,
      upc,
      weight,
      dimensions,
      mpn,
      shiped,
      accountingStock,
      OpeningStockValue,
      oppeningStock,
      physicalStock,
      inventory_type,
      received,
      reorder,
      ean,
      isbn,
      taxable,
      type,
      description,
      manufacturer,
      brand,
      tax,
      itemGroup,
      varient,
      association = [],
          platform_type
    } = item;

    return (
      <Fragment>
        <CustomModal
          size="medium"
          className={classNames('syncModal')}
          showModal={openModal}
          title={wooComItemConfirmDialogContent[modelIndex].heading}
          renderActions={false}
          onClose={() => {
            this.setState({ openModal: false });
          }}
          classNames="shopify-popup-main-set"
        >
          {' '}
          {this.modalContentHandler(modelIndex)}{' '}
        </CustomModal>

        <div className="float-left w-100 position-relative items_details">
          {!this.props.inModal && (
            <div className="close_icon">
              <MdClose
                onClick={() => {
                  let dataFromLS = getObjFromLS('module');
                  setObjInLS('module', {
                    ...dataFromLS,
                    presistDetailPage: false,
                  });
                  setValueInLS('fullscreen', 'true');
                  sessionStorage.setItem('once', true);

                  this.props.history.push('/r');
                }}
              />
            </div>
          )}
          <div
            className={`${
              !this.props?.match?.params.id && this.props.inModal
                ? 'float-left w-100 '
                : 'float-left w-80 UIUX-item-single'
            }`}
          >
            <div
              className="float-left w-100 mt_1 px_5"
              style={{ marginBottom: '20px' }}
            >
              <ItemDetailsImages
                images={images}
                primary_image={primary_image}
                onDropImages={this.onDropImages}
                imageUploading={imageUploading}
                setPrimary={(currentImage) => this.setasPrimary(currentImage)}
                inModal={this.props.inModal || false}
              />
              <ItemInfo
                info={{
                  unit: unit,
                  upc: upc,
                  mpn: mpn,
                  ean: ean,
                  isbn: isbn,
                  taxable: taxable,
                  itemType: type,
                  inventory_type,
                  description,
                  manufacturer,
                  brand,
                  weight,
                  dimensions,
                  tax,
                  sku,
                  itemGroup,
                  varient,
                  platform: platform_type,
                  history: this.props.history,
                  inModal: this.props.inModal || false,
                }}
              />
            </div>
            <ItemQuantity
              shiped={shiped}
              received={received}
              reorder={reorder}
              list={association}
              handleUnlink={this.handleUnlink}
              current={association}
              inModal={this.props.inModal || false}
            />
            <StockQuantity
              accountingStock={accountingStock}
              physicalStock={physicalStock}
              oppeningStock={oppeningStock}
              OpeningStockValue={OpeningStockValue}
              reorder={reorder}
              symbol={this.state.currency.symbol}
              inventory_type={inventory_type}
            />

            <div className="items_sub_tabs items-tabs-responsive">
              <SubTabs
                tabs={this.tabsForItems}
                currentTab={currentTab}
                className={this.props.className}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderSidebar() {
    const {
      purchaseUnitPrice,
      purchaseAccount,
      purchaseDescription,
      taxFull,
      vendor,
      salesUnitPrice,
      salesAccount,
      salesDescription,
    } = this.state.item;
    return (
      <Fragment>
        {purchaseAccount && (
          <Box title="Purchase Information">
            <ItemAccountInformation
              price={`${this.state.currency.symbol}${formatNumber(
                purchaseUnitPrice
              )}`}
              account={purchaseAccount.title}
              description={purchaseDescription}
              preferredVendor={vendor}
            />
          </Box>
        )}
        <Box title="Sales Information">
          <ItemAccountInformation
            price={`${this.state.currency.symbol}${formatNumber(
              salesUnitPrice
            )}`}
            account={salesAccount.title}
            description={salesDescription}
            Tax={taxFull}
          />
        </Box>
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    if (this.state.accessDenied) return <AccessDenied type="section" />;
    return (
      <Fragment>
        {this.ImageCropModal()}
        {this.renderLayoutView()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(ItemDetails, {
  name: 'Items',
  tableIcon: 'general_module_icon',
  Icon: NewItemIcon,
  baseUrl: 'items',
  deleteName: 'Item',
  redirectUrl: '/items',
  onlyMenu: true,
  showName: true,
  permissionName: 'item',
});
