import React, { Component } from 'react';
import moment from 'moment';

import { restRequest } from '../../../Helpers/RequestHelper';
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import BillFormEnhancer, {
  getTaxAmount,
  getItemTotalAmount,
} from './BillFormEnhancer';
import Loader from '../../Loader/Loader';
import PlanExceeded from '../../PlanExceeded/PlanExceeded';
import { checkError } from '../../../Helpers/AuthHelper';
import { Prompt } from 'react-router-dom';
import { GetDateString } from '../../../Helpers';

class BillForm extends Component {
  type = this.props.type || 'add'; // add, edit
  purchaseOrderId = this.props.purchaseOrderId || null; // required for 'add'
  inModal = this.props.inModal || null;
  vendorId =
    (window.location.search.includes('contactId') &&
      window.location.search.split('=')[1]) ||
    null;

  state = {
    loading: true,
    purchaseOrderId: this.props.purchaseOrderId || null,
    bill: {
      billNo: '',
      note: '',
      orderNo: '',
      billDate: moment(getOrganizationDate()),
      dueDate: moment(getOrganizationDate()),
      items: [],
      transactionDiscountType: 'percent', // percent, amount
      transactionDiscount: 0,
      adjustment: 0,
      vendorId: null,
      vendorObj: {},
      billItemsLength: null,
      hasTermsAndConditions: false,
    },
    itemsList: [],
    termsAndConditions: '',
    currency: {},
    taxList: [],
    accountsList: { expense: [], inventory: [] },
    vendorsList: [],
    warehousesList: [],
    yo: [],
    issuedPurchaseOrdersList: [],
    planExceeded: null,
    previousPurchasePrices: [],
    showPrompt: false,
    cofirmState: false,
  };

  emptyItem = {
    id: null,
    dragDropID: GetDateString(),
    purchaseOrderItemDetailId: null,
    warehouseId: null,
    name: '',
    images: '',
    sku: '',
    accountId: null,
    previousPurchasePrice: 0,
    quantity: 1,
    maxBillableQuantity: 0,
    unit: 'unit',
    unitPrice: null,
    tax: {
      id: null,
      rate: 0,
      name: '',
    },
    extra_description: '',
    taxAmount: 0,
    total: 0,
  };

  componentDidMount() {
    document.title = `${this.type === 'edit' ? 'Edit' : 'Add'} Bill`;
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
    if (this.inModal) {
      sessionStorage.setItem('modal', true); //testing
    }
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate() {
    if (this.inModal) {
      sessionStorage.setItem('modal', true);
    } ///testing purpose
  }
  fetchData = async () => {
    this.setState({ loading: true });

    switch (this.type) {
      case 'edit':
        await this.fetchBillEditDetails(this.props.billId);
        break;
      case 'add':
      default:
        await this.fetchBillDetails(this.purchaseOrderId, this.vendorId);
    }

    this.setState({ loading: false });
  };

  getTax(id, array = []) {
    return array.find((tax) => tax.id === id);
  }

  prepareAddItems(itemsArray, taxes, warehouseId) {
    return itemsArray.map((item) => ({
      id: item.item_id,
      dragDropID: GetDateString(),
      purchaseOrderItemDetailId: item.purchase_order_item_detail_id,
      warehouseId:
        item.purchase_order_item_warehouse_id !== null
          ? item.purchase_order_item_warehouse_id
          : warehouseId,
      name: item.item_name,
      images: item.item_images[0],
      sku: item.item_sku,
      accountId: item.purchase_order_item_account_id,
      quantity: item.quantity_billable_without_received,
      maxBillableQuantity: item.quantity_billable_without_received,
      unit: item.item_unit,
      unitPrice: item.purchase_order_item_unit_price,
      upc: item.item_upc,
      mpn: item.item_mpn,
      ean: item.item_ean,
      isbn: item.item_isbn,
      extra_description: item.extra_description,
      tax: {
        id: item.purchase_order_item_tax_id
          ? this.getTax(item.purchase_order_item_tax_id, taxes)?.id
          : null,
        rate: item.purchase_order_item_tax_id
          ? this.getTax(item.purchase_order_item_tax_id, taxes)?.rate
          : 0,
        name: item.purchase_order_item_tax_id
          ? this.getTax(item.purchase_order_item_tax_id, taxes)?.name
          : '',
      },
      taxAmount: item.purchase_order_item_tax_id
        ? getTaxAmount(
            item.quantity_billable_without_received,
            item.purchase_order_item_unit_price,
            this.getTax(item.purchase_order_item_tax_id, taxes)?.rate
          )
        : 0,
      total: getItemTotalAmount(
        item.quantity_billable_without_received,
        item.purchase_order_item_unit_price
      ),
    }));
  }

  prepareItemsList(itemsArray, taxes, warehouseId) {
    return itemsArray.map((item) => ({
      id: item.id,
      purchaseOrderItemDetailId: null,
      warehouseId: warehouseId,
      name: item.name,
      images: item.images,
      sku: item.sku,
      accountId: item.purchase_account_id,
      quantity: 1,
      inventory_type: item.inventory_type,
      maxBillableQuantity: null,
      unit: item.unit,
      unitPrice: item.purchase_unit_price,
      upc: item.upc,
      mpn: item.mpn,
      ean: item.ean,
      isbn: item.isbn,
      extra_description: item.extra_description,
      tax: {
        id: item.tax_id ? this.getTax(item.tax_id, taxes, item)?.id : null,
        rate: item.tax_id ? this.getTax(item.tax_id, taxes, item)?.rate : 0,
        name: item.tax_id ? this.getTax(item.tax_id, taxes, item)?.name : '',
      },
      taxAmount: 0,
    }));
  }

  fetchBillDetails = async (purchaseOrderId, vendorId) => {
    const url = vendorId
      ? `bills/create${vendorId ? `?page=1&contactId=${vendorId}` : ''}`
      : `bills/create${
          purchaseOrderId ? `?purchase_order_id=${purchaseOrderId}` : ''
        }`;
    await restRequest('get', url)
      .then((res) => {
        // this.setState({
        //   vendorId: res.vendor_id,
        // });

        // console.log(,res.vendor_id);
        let warehouseList = [];

        warehouseList.push(
          res.warehouses.find((warehouse) => warehouse.is_primary === true)
        );

        // if (this.vendorId) {
        //   res.vendors = res.vendors.find(
        //     (vendor) => parseInt(vendor.id) === parseInt(this.vendorId)
        //   );
        // }

        // Removes inactive warehouse from list
        res.warehouses = res.warehouses.filter((list) => list.is_active === 1);

        const itemsList = this.prepareItemsList(
          res.billable_items,
          res.taxes,
          warehouseList[0].id
        );
        const billableItems = res.bill_details
          ? this.prepareAddItems(
              res.bill_details,
              res.taxes,
              warehouseList[0].id
            )
          : [];
        const bill = {
          ...this.state.bill,
          billNo: res.bill_no,
          // note: res.note,
          termsAndConditions: res.bill_preference.terms
            ? res.bill_preference.terms
            : '',
          orderNo: res.order_no,
          vendorObj: {
            label: res.vendors[0]?.display_name || '',
            value: res.vendors[0]?.id || null,
          },
          transactionDiscountType: res.discount_type
            ? res.discount_type
            : this.state.bill.transactionDiscountType,
          transactionDiscount: res.discount_transaction_level
            ? res.discount_transaction_level
            : 0,
          items: this.inModal
            ? [...billableItems]
            : [...billableItems, this.emptyItem], //HANDLED THE EMPTY BILL ADDITIOn in PO module bill create, In New Bill PO item will be there.
          adjustment: res.adjustment ? res.adjustment : 0,
          orderDate: res.order_date ? moment(res.order_date) : null,
          billDate:
            res?.order_date &&
            moment(res.order_date) > moment(getOrganizationDate())
              ? moment(res.order_date)
              : moment(getOrganizationDate()),
          dueDate:
            res?.order_date &&
            moment(res.order_date) > moment(getOrganizationDate())
              ? moment(res.order_date)
              : moment(getOrganizationDate()),
          billItemsLength: res?.bill_details?.length,
        };

        if (this.inModal) bill.vendorId = res.vendors[0]?.id;

        this.setState({
          bill: { ...this.state.bill, ...bill },
          itemsList: !purchaseOrderId ? itemsList : [],
          currency: res.base_currency,
          taxList: res.taxes,
          vendorsList: res.vendors,
          warehousesList: res.warehouses,
          accountsList: {
            expense: res.expense_accounts,
            inventory: res.inventory_accounts,
          },
          checkDirectBill: purchaseOrderId ? true : false,
          indirectBill: false,
        });

        let promises = [];

        billableItems.forEach((item, index) => {
          let POPURL = null;
          if (purchaseOrderId) {
            POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${bill.vendorId}&item_id=${item.id}&object=purchaseOrder&object_id=${purchaseOrderId}`;
          } else {
            POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${bill.vendorId}&item_id=${item.id}`;
          }
          if (item.id) promises.push(restRequest('get', POPURL));
        });

        Promise.all(promises).then((promisRes) => {
          this.setState({ previousPurchasePrices: promisRes });
        });
      })
      .catch((error) => {
        checkError(error);
        this.props.handleToast(error, 'error');
        if (this.props.inModal) {
          this.props.onClose();
        } else {
          this.setState({
            planExceeded: error.response && error.response.data.status,
            loading: false,
          });
          if (error.response) {
            this.props.handleToast(error.response.data.message, 'error');
          }
        }
      });
  };
  prepareEditItems(itemsArray) {
    return itemsArray.map((item) => {
      return {
        id: item.item_id,
        dragDropID: GetDateString(),
        purchaseOrderItemDetailId: item.purchase_order_item_detail_id,
        billItemDetailId: item.id,
        warehouseId: item.warehouse_id,
        name: item.item.name,
        images: item.item.images[0],
        inventory_type: item.item.inventory_type,
        sku: item.item.sku,
        unit: item.item.unit,
        accountId: item.account_id,
        quantity: item.quantity,
        maxBillableQuantity: item.quantity,
        unitPrice: item.rate,
        extra_description: item.extra_description,
        tax: {
          id: item.tax ? item?.tax?.id : null,
          rate: item.tax ? item?.tax?.rate : 0,
          name: item.tax ? item?.tax?.name : '',
        },
        taxAmount: item.tax
          ? getTaxAmount(item.quantity, item.rate, item.tax.rate)
          : 0,
        total: getItemTotalAmount(item.quantity, item.rate),
      };
    });
  }

  fetchBillEditDetails = async (billId) => {
    await restRequest('get', `bills/${billId}/edit`)
      .then(async (res) => {
        const billedItems = this.prepareEditItems(
          res?.bill_details.bill_info.bill_item_details
        );
        const itemsList = this.prepareItemsList(
          res?.billable_items,
          res?.taxes,
          res?.warehouses[0].id
        );
        let purchaseOrderItems = this.prepareEditItems(
          res?.bill_details.bill_info.bill_item_details
        );
        const checkDirectBill = purchaseOrderItems.some(
          (item) => item.purchaseOrderItemDetailId !== null
        );
        this.setState((state) => ({
          bill: {
            ...state.bill,
            objectId: billId,
            billNo: res?.bill_details?.bill_info?.bill_no,
            note: res?.bill_details?.bill_info?.note,
            orderNo: res?.bill_details?.bill_info?.order_no,
            billDate: moment(res?.bill_details?.bill_info?.bill_date),
            dueDate: moment(res?.bill_details?.bill_info?.due_date),
            orderDate: res?.bill_details?.bill_info?.purchase_orders[0]
              ?.order_date
              ? moment(
                  res?.bill_details?.bill_info?.purchase_orders[0]?.order_date
                )
              : null,
            poId: res?.bill_details.bill_info?.purchase_orders[0]?.id || '',
            items: [...billedItems],
            transactionDiscountType:
              res?.bill_details.bill_info.discount_type ||
              state.bill.transactionDiscountType,
            transactionDiscount:
              res?.bill_details.bill_info.discount_transaction_level || 0,
            adjustment: res?.bill_details.bill_info.adjustment || 0,
            vendorId: res?.bill_details.bill_info.vendor_id,
            vendorObj: {
              label: res?.bill_details.bill_info.vendor.display_name,
              value: res?.bill_details.bill_info.vendor.id,
            },
            billItemsLength:
              res?.bill_details.bill_info.bill_item_details.length,
            termsAndConditions: res?.bill_details.bill_info.terms_and_condition
              ? res?.bill_details.bill_info.terms_and_condition
              : res?.bill_preference.terms,
            hasTermsAndConditions: res?.bill_details.bill_info
              .terms_and_condition
              ? true
              : false,
          },
          itemsList: checkDirectBill ? [] : itemsList,
          indirectBill: res?.bill_details.bill_info?.order_no ? true : false,
          checkDirectBill: checkDirectBill,
          vendorsList: res?.vendors,
          taxList: res?.taxes,
          // yo: [...hey],
          issuedPurchaseOrdersList: res?.billable_issued_purchase_orders,
          warehousesList: res?.warehouses,
          currency: res?.bill_details.base_currency,
          accountsList: {
            expense: res?.expense_accounts || [],
            inventory: res?.inventory_accounts || [],
          },
        }));
        let promises = [];
        purchaseOrderItems = [...billedItems];
        purchaseOrderItems.forEach((item, index) => {
          promises.push(
            restRequest(
              'get',
              `purchaseorders/previouspurchaseprice?vendor_id=${res.bill_details.bill_info.vendor_id}&item_id=${item.id}&object=bill&object_id=${billId}`
            )
          );
        });

        Promise.all(promises).then((promisRes) => {
          this.setState({ previousPurchasePrices: promisRes });
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        if (this.props.inModal) {
          this.props.onClose();
        } else {
          this.setState({
            planExceeded: error.response && error.response.data.status,
            loading: false,
          });
          if (error.response) {
            this.props.handleToast(error.response.data.message, 'error');
          }
        }
      });
  };

  getTaxInfo = (item, taxList = []) => {
    if (item?.item_tax_id) {
      let tax = taxList.find((tax) => tax.id === item.item_tax_id);
      return {
        id: tax.id,
        rate: tax.rate,
        name: tax.name,
      };
    }
    return {
      id: null,
      rate: 0,
      name: '',
    };
  };

  submitRequestType() {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      default:
        return 'post';
    }
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `bills/${this.props.billId}`;
      case 'add':
      default:
        return `bills`;
    }
  }

  successMessage = () => {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Bill has been updated', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Bill added successfully', 'success');
        break;
    }
  };

  handleShowPrompt = (status = true) => this.setState({ showPrompt: status });
  handleTotalCheck = (check = true) => {
    this.setState({
      cofirmState: check,
    });
  };

  renderBillForm() {
    const {
      bill,
      itemsList,
      accountsList,
      previousPurchasePrices,
      taxList,
      hasTermsAndConditions,
      yo,
      currency,
      purchaseOrderId,
      vendorsList,
      warehousesList,
      issuedPurchaseOrdersList,
      cofirmState,
      indirectBill,
    } = this.state;
    const {
      onClose,
      handleToast,
      onSubmit,
      history,
      handleBillPrompt = () => null,
    } = this.props;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <BillFormEnhancer
          bill={{ ...bill }}
          yo={yo}
          itemsList={itemsList}
          emptyItem={this.emptyItem}
          accountsList={accountsList}
          billType={this.type}
          taxList={taxList}
          purchaseOrderId={purchaseOrderId}
          previousPurchasePrices={previousPurchasePrices}
          billItemsLength={bill.billItemsLength}
          vendorIdTransaction={this.vendorId}
          vendorsList={vendorsList}
          warehousesList={warehousesList}
          hasTermsAndConditions={hasTermsAndConditions}
          issuedPurchaseOrdersList={issuedPurchaseOrdersList}
          currency={currency}
          inModal={this.inModal}
          history={history}
          onClose={onClose}
          type={this.type}
          checkDirectBill={this.state.checkDirectBill}
          handleToast={handleToast}
          requestType={this.submitRequestType()}
          requestUrl={this.submitRequestUrl()}
          successMessage={this.successMessage}
          onSubmit={onSubmit}
          cofirmState={cofirmState}
          handleTotalCheck={this.handleTotalCheck}
          setPrompt={this.handleShowPrompt}
          handleBillPrompt={handleBillPrompt}
          indirectBill={indirectBill}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.planExceeded === false) return <PlanExceeded />;
    return this.renderBillForm();
  }
}

export default BillForm;
