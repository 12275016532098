import React, { Component } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import Loader from '../../Components/Loader/Loader';
import AccountFormEnhancer from './AccountFormEnhancer';
import { checkError } from '../../Helpers/AuthHelper';
import './AccountForm.css';

class AccountForm extends Component {
  state = {
    title: null,
    currency: {},
    loading: true,
    account: null,
    account_types: [],
    parent_accounts: [],
    account_subtypes: [],
    account_taxes: null,
    accountTypeslug: null,
    accountSubtypeSlug: null,
    account_categories: null,
    markedBalancesheet: null,
    selected_category_id: null,
  };
  componentDidMount() {
    document.title = this.props.edit
      ? 'Edit Inventory Account'
      : 'New Inventory Account';
    if (this.props.edit) {
      this.fetchEditaccountsData(this.props.id).then(() => {
        this.setState({ loading: false });
      });
    } else this.fetchAccountGroups();
  }
  fetchAccountGroups = () => {
    restRequest(
      'get',
      `accounts/create${
        this.props.accountType ? `?type=${this.props.accountType}` : ''
      }`
    )
      .then((account) => {
        let markedBalancesheetData = [];
        account.account_categories.filter((data) => {
          return (
            data.id === account.selected_category_id &&
            markedBalancesheetData.push(Boolean(data.balance_sheet))
          );
        });
        console.log(markedBalancesheetData, 'markedBalancesheetData');
        this.setState({
          account: account.account,
          currency: account.currency,
          account_taxes: account.taxes,
          account_types: account.account_types,
          account_subtypes: account.account_subtypes,
          account_categories: account.account_categories,
          selected_category_id: account.selected_category_id,
          accountTypeslug: account.account_types[0].basic_type.slug,
          markedBalancesheet: !!markedBalancesheetData[0],
          // !!account.account_categories[0].balance_sheet,
          accountSubtypeSlug: account.account_subtypes[0]?.basic_sub_type?.slug,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  };
  fetchEditaccountsData = async (id) => {
    await restRequest('get', `accounts/${id}/edit`)
      .then((account) => {
        this.setState({
          account: account.account,
          account_categories: account.account_categories,
          account_category_id: account?.account?.account_category_id,
          currency: account.currency,
          account_no: account.account.account_no,
          balance: account.account.balance,
          current_balance: account.account.account_current_balance,
          parent_account_id: account.account.parent_account_id,
          tax_id: account.account.tax_id,
          bank_account_no: account.account.bank_account_no,
          title: account.account.title,
          description: account.account.description,
          account_taxes: account.taxes,
          account_types: account.account_types,
          parent_accounts: account.parent_accounts,
          is_sub_account: account.account.is_sub_account,
          account_subtypes: account.account_subtypes,
          selected_category_id: account.selected_category_id,
          accountTypeslug: account.account_types[0].basic_type.slug,
          markedBalancesheet: !!account.account_categories[0].balance_sheet,
          accountSubtypeSlug: account.account_subtypes[0]?.basic_sub_type?.slug,
          loading: false,
        });
      })

      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };
  handleSubmit = (values, props, setSubmitting) => {
    if (this.props.id && this.props.edit) {
      let payload = {
        ...values,
        currency: values?.currency?.id,
        account_type_id:
          typeof values?.account_type_id == 'number'
            ? values?.account_type_id
            : values.account_type_id?.id,
        account_subtype_id:
          typeof values.account_subtype_id == 'number'
            ? values.account_subtype_id
            : values.account_subtype_id?.id,
      };

      restRequest('put', `accounts/${this.props.id}`, payload)
        .then((updatedData) => {
          this.props.onSubmit(); // return the added employee data
          this.renderNotification();
        })
        .catch((error) => {
          this.props.handleToast(error, 'error');
          // setSubmitting(false);
        });
    } else {
      let payload = {
        ...values,
        currency: values?.currency?.id,
        account_type_id:
          typeof values?.account_type_id == 'number'
            ? values?.account_type_id
            : values.account_type_id?.id,
        account_subtype_id:
          typeof values.account_subtype_id == 'number'
            ? values.account_subtype_id
            : values.account_subtype_id?.id,
      };

      restRequest('post', `accounts`, payload)
        .then((data) => {
          if (this.props.itemModal) {
            this.props.onSubmitForm(data);
            if (this.props.onClose) this.props.onClose(); // Close the modal after submission
            this.renderNotification();
          } else {
            this.renderNotification();
            this.props.onSubmit();
          }
          // console.log(
          //   this.props.history.location.pathname,
          //   'history.location.pathname'
          // );
          // // if (history?.location?.pathname.includes('/deliverymethods')) {
          // //   history.push('/deliverymethods');
          // // }
          // this.props.itemModal && this.props.onSubmitForm(data);
          // this.renderNotification();
        })
        .catch((error) => {
          this.props.handleToast(error, 'error');

          // setSubmitting(false);
        });
    }
  };
  renderNotification() {
    if (this.props.edit) {
      this.props.handleToast('Account edited successfully', 'success');
    } else {
      this.props.handleToast('Account added successfully', 'success');
    }
  }
  handleChange = (values) => {
    // console.log('Form submitted:>>>>>>>>>>>>>handlechange', values);
  };
  renderAccountForm = () => (
    <AccountFormEnhancer
      {...this.props}
      {...this.state}
      state={this.setState}
      myState={this.state}
      onSubmit={this.handleSubmit}
      account={this.state.account}
      currency={this.state.currency}
      handleChange={this.handleChange}
      account_types={this.state.account_types}
      account_taxes={this.state.account_taxes}
      accountGroups={this.state.accountGroups}
      accountTypeslug={this.state.accountTypeslug}
      parent_accounts={this.state.parent_accounts}
      account_subtypes={this.state.account_subtypes}
      accountSubtypeSlug={this.state.accountSubtypeSlug}
      account_categories={this.state.account_categories}
      markedBalancesheet={this.state.markedBalancesheet}
      selected_category_id={this.state.selected_category_id}
    />
  );

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderAccountForm();
  }
}

export default AccountForm;
