import React, { useEffect, useState } from 'react';
import { checkError, restRequest } from '../../../Helpers';
import { Field, Form, FormAction } from '../../../Components/Form';
import { Loader } from '../../../Components';
import { withFormik } from 'formik';

const formEnhancer = withFormik({
  handleSubmit: (values, bag) => {
    const filteredData = Object.keys(values)
      .filter(
        (key) => typeof values[key] !== 'object' && values[key] !== undefined
      )
      .reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});
    handleFormSubmit(filteredData, bag);
  },
});
const handleFormSubmit = (
  values,
  { setSubmitting, props: { successMessage, handleToast, setPrompt, onSubmit } }
) => {
  const value = { value: { ...values } };
  restRequest('put', 'old_accounts/update', value)
    .then((res) => {
      onSubmit();
      setPrompt(false);
      successMessage();
    })
    .catch((error) => {
      setSubmitting(false);
      checkError(error);
      handleToast(error, 'error');
    });
};
const MapOldAccount = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setFieldValue, values, handleSubmit } = props;
  useEffect(() => {
    document.title = 'Map Old Accounts';
    fetchAccounts();
  }, []);
  const fetchAccounts = () => {
    setIsLoading(true);
    restRequest('get', 'old_accounts').then((res) => {
      setData({ ...res.data });
      setIsLoading(false);
    });
  };
  return (
    <div style={{ margin: 'auto', width: '250px', 'margin-top': '20px' }}>
      {!isLoading ? (
        <Form onSubmit={handleSubmit}>
          <h2>Mapping Old Accounts</h2>
          {data?.accounts?.map((oldAcc, i) => (
            <Field
              key={i}
              required
              size="large"
              type="dropdown"
              id={oldAcc?.id}
              clearable={false}
              dropdownValue="id"
              dropdownLabel="title"
              name={oldAcc?.title}
              value={values[oldAcc?.id] || ''}
              className="reg_timezone_field"
              options={data?.account_options || []}
              placeholder={`Select ${oldAcc.label}`}
              handleChange={(option) => {
                setFieldValue(oldAcc?.id, option ? option.value : null);
              }}
              // error={touched.oldAcc?.id && errors.email}
            />
          ))}

          <FormAction disabled={false} className="clearfix" />
        </Form>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default formEnhancer(MapOldAccount);
