export const prepareDataForTable = (data) =>
  data.map((account) => ({
    id: account.id,
    Name: {
      id: 'title',
      sortable: true,
      getValue: () => account.title,
    },
    Category: {
      id: 'account_category_name',
      sortable: true,
      getValue: () => account.account_category_name,
    },

    Type: {
      id: 'account_type_name',
      sortable: true,
      getValue: () => account.account_type_name,
    },
    'Sub Type': {
      id: 'account_subtype_name',
      sortable: true,
      getValue: () => account.account_subtype_name,
    },
    'Parent Account': {
      id: 'parent_account_name',
      alignRight: true,
      sortable: true,
      getValue: () =>
        account.parent_account_name && account.parent_account_name,
    },
    Currency: {
      id: 'currency',
      alignRight: true,
      sortable: true,
      getValue: () => `${account?.account_currency?.symbol}`,
    },
    'Bank Account Number': {
      id: 'bank_account_no',
      alignRight: true,
      sortable: true,
      getValue: () => account?.bank_account_no,
    },
    'Default Tax': {
      id: 'tax_name',
      alignRight: true,
      sortable: true,
      getValue: () => account?.tax_name && account?.tax_name,
    },
    Number: {
      id: 'account_no',
      alignRight: true,
      sortable: true,

      getValue: () => account.account_no && account.account_no,
    },
    Balance: {
      id: 'balance',
      alignRight: true,
      sortable: true,
      getValue: () => `${account?.account_currency?.symbol}${account?.balance}`,
    },
  }));
